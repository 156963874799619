import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-dashboard',
      }
    ]
  },
  {
    label: 'Customer',
    main: [
      {
        state: 'customer-engage',
        short_label: 'E',
        name: 'Engage',
        type: 'link',
        icon: 'ti-link'
      },
      {
        state: 'customer-list',
        short_label: 'L',
        name: 'Track Request',
        type: 'link',
        icon: 'ti-view-list-alt'
      },
      {
        state: 'customer-consent',
        short_label: 'L',
        name: 'Consents',
        type: 'link',
        icon: 'ti-agenda'
      }
    ]
  },
  {
    label: 'Admin Functions',
    main: [
      {
        state: 'consent-templates',
        short_label: 'A',
        name: 'Consent Templates',
        type: 'sub',
        icon: 'ti-layers',
        children: [
          {
            state: 'create-consent-template',
            name: 'Create',
            icon: 'ti-user'
          },
          {
            state: 'approve-consent-template',
            name: 'List',
            icon: 'ti-id-badge'
          },
          {
            state: 'pending-consent-template',
            name: 'Approve',
            icon: 'ti-user'
          }
        ]
      },
      {
        state: 'channels',
        short_label: 'C',
        name: 'Channels',
        type: 'sub',
        icon: 'ti-layers',
        children: [
          {
            state: 'create-channel',
            name: 'Create',
            icon: 'ti-user'
          },
          {
            state: 'approve-channel',
            name: 'List',
            icon: 'ti-id-badge'
          },
          {
            state: 'pending-channel',
            name: 'Approve',
            icon: 'ti-user'
          }
        ]
      },
      {
        state: 'profiles',
        short_label: 'A',
        name: 'API Profiles',
        type: 'sub',
        icon: 'ti-layers',
        children: [
          {
            state: 'create-profile',
            name: 'Create',
            icon: 'ti-user'
          },
          {
            state: 'approve-profile',
            name: 'List',
            icon: 'ti-id-badge'
          },
          {
            state: 'pending-profile',
            name: 'Approve',
            icon: 'ti-user'
          }
        ]
      }
    ]
  },
  {
    label: 'Users',
    main: [
      {
        state: 'users-role',
        short_label: 'R',
        name: 'User Role',
        type: 'sub',
        icon: 'ti-user',
        children: [
          {
            state: 'create-users-role',
            name: 'Create',
            icon: 'ti-user'
          },
          {
            state: 'approve-users-role',
            name: 'List',
            icon: 'ti-id-badge'
          },
          {
            state: 'pending-users-role',
            name: 'Approve',
            icon: 'ti-user'
          }
        ]
      },
      {
        state: 'manage-user',
        short_label: 'M',
        name: 'Manage User',
        type: 'sub',
        icon: 'ti-layers',
        children: [
          {
            state: 'create-manage-user',
            name: 'Create',
            icon: 'ti-user'
          },
          {
            state: 'approve-manage-user',
            name: 'List',
            icon: 'ti-id-badge'
          },
          {
            state: 'pending-manage-user',
            name: 'Approve',
            icon: 'ti-user'
          }
        ]
      }
    ]
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
