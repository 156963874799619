import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserLogout } from 'src/app/services/user-login/user-login';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  subscription: Subscription;

  constructor(private router: Router) { }

  ngOnInit() {
    this.logout();
  }

  logout(){
    console.info('logout called');
    // reset login status
    const userLogout: UserLogout = {
      userId: sessionStorage.getItem('currentUser')
    }
    sessionStorage.clear();
    this.router.navigate(['/login']);
    sessionStorage.removeItem('currentUser');
  }
}
