import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  get apiBaseUrl() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiBaseUrl;
  }

  get userAuthUrl() {
    
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.userAuthUrl;
  }

  get userRepo() {
    
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.userRepo;
  }

  get logoImagePath() {
    
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.logoImagePath;
  }

  get smallLogoPath() {
    
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.smallLogoPath;
  }
}
