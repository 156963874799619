import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './modules/guard/auth.guard';
import { LogoutComponent } from './modules/auth/logout/logout.component';
import { Role } from './services/user-login/role';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'customer',
        loadChildren: './modules/customer/customer.module#CustomerModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'customer-engage',
        loadChildren: './modules/customer/consent-request/consent-request.module#ConsentRequestModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'customer-list',
        loadChildren: './modules/customer/customer-list/customer-list.module#CustomerListModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'customer-consent',
        loadChildren: './modules/customer/customer-consent/customer-consent.module#CustomerConsentModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'profiles',
        loadChildren: './modules/profiles/profiles.module#ProfilesModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'channels',
        loadChildren: './modules/channels/channels.module#ChannelsModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'consent-templates',
        loadChildren: './modules/consent-templates/consent-templates.module#ConsentTemplatesModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'manage-user',
        loadChildren: './modules/manage-user/manage-user.module#ManageUserModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'users-role',
        loadChildren: './modules/users-role/users-role.module#UsersRoleModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './modules/auth/login/basic-login.module#BasicLoginModule'
      },
      {
        path: 'logout',
        component: LogoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
