import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertModel {
  message: string;
}

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  message: string;
  constructor() {
    super();
  }
}

