
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body" style="font-size: 14px;text-align: center;">
      <p>{{message}}</p>
    </div>
    <div align="center" style="float: center;">
      <button type="button" class="btn btn-primary" (click)="close()">OK</button>
    </div>
  </div>
</div>